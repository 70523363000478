import React from 'react';
import { randomKey, convertTrueFalseStringToBoolean } from '../../functions/common';

const AdminInfo = ({ status, moderationState, path }) => {
  const showDrafts = convertTrueFalseStringToBoolean(process.env.SHOW_DRAFT_CONTENT),
    pageStatus = status,
    moderationStateMap = {
      draft: 'Draft',
      published: 'Published',
      unpublished: 'Unpublished',
      needs_review: 'Needs review',
      to_be_deleted: 'to be deleted',
    };

  if (!showDrafts || moderationState === 'published') {
    if (!moderationStateMap.hasOwnProperty(moderationState)) {
      console.error(`Invalid moderation state: ${moderationState} at ${path}`);
    }
    return <React.Fragment key={randomKey()}></React.Fragment>;
  }

  return pageStatus === false ? (
    <div className="callout-box admin-only">
      <p>This page is marked as '{moderationStateMap[moderationState]}'</p>
    </div>
  ) : (
    <React.Fragment key={randomKey()}></React.Fragment>
  );
};

export default AdminInfo;
