import React from 'react';

export default function Script({ src, props }) {
  // Prepare the script markup to prevent it flickering and failing to run.
  // Note if the script inserts content into the page at that point,
  // a warning may be thrown as the dangerouslySetInnerHTML output won't
  // match the below.
  const htmlMarkup = () => {
    return {
        __html: `<script src="${src}" ${props ? props : ''}></script>`,
    };
  };

  return <div dangerouslySetInnerHTML={htmlMarkup()} />;
}
