// @ts-nocheck
import React from 'react';
import Iframe from './Iframe';
import { getKARdata } from '../fragments/kardata';
import { getCIARdata } from '../fragments/ciardata';
import marineZonesActivities from '../../../static/js/marine-zones-permitted-activities.yml';
import { useLocation } from '@reach/router';
import { randomKey } from '../../functions/common';

let whichPark = process.env.GATSBY_PARK;
let locationString = null;
// @ts-nocheck

//! WARNING: For pages located at /access/, this component serves 2 distinctly
//! separate elements - an embedded map AND a table. Not ideal, but it was
//! originally designed to just handle the Kakadu Access Report

/**
 * Embeds a map with specified parameters and points.
 * @param {Object} props - The properties for the map component.
 * @param {string} props.title - The title of the embedded map.
 * @param {string} props.wrapperId - The id of the map wrapper.
 * @param {string} props.mapId - Manually set the map id
 * @param {number} props.latNum - The latitude of the map center.
 * @param {number} props.lonNum - The longitude of the map center.
 * @param {number} props.zoom - The zoom level of the map.
 * @param {Array} props.points - The JSON array of points on the map (optional).
 * @param {string} props.style - The visual style of the map, default 'outdoor'
 * @returns {React.Element} - The map embedded in an iframe.
 */

function MapEmbed(props) {
  const frameTitle = props.title;
  const latNum = props.latNum;
  const lonNum = props.lonNum;
  const zoom = props.zoom;
  const points = props.points;
  const style = props.style || 'outdoor';
  const location = useLocation();
  const wrapperId = props.wrapperId;
  const mapId = location.pathname === '/access/' ? 'accessReportMap' : props.mapId || 'map';
  const parkHasRegions = whichPark === 'knp' ? true : false;

  // Load mapbox for normal map embed
  let sourceDoc = `<head></head><body style="margin:0; border:0"><div id="${mapId}" style="width:100%;height:100%"></div>`;

  // Pass in any variables we want the iframe to have, and add common javascript
  // functions, mapbox code, and styles for popup boxes.
  sourceDoc += `
  <script>
    const whichPark = '${whichPark}';
    const mapData = {
      id: ${mapId},
      lat: ${latNum},
      lon: ${lonNum},
      park: '${whichPark}',
      style: '${style}',
      zoom: ${zoom},
    };
  </script>
  <script src="/js/jscommon.js"></script>
  <script src="https://cdn.maptiler.com/mapbox-gl-js/v1.13.2/mapbox-gl.js"></script>
  <link href="https://cdn.maptiler.com/mapbox-gl-js/v1.13.2/mapbox-gl.css" rel="stylesheet"/>
  <link href="/js/mapbox-style.css" rel="stylesheet"/>
  <script>map_locations=\`${points}\`;</script>`;

  // Only load AMP data for AMP site
  if (whichPark === 'amp') {
    sourceDoc += `<script src="/js/marine-parks-geodata.js"></script>
    <script>marineZonesActivities=${JSON.stringify(marineZonesActivities)};</script>`;
  }

  // I have no idea where react is expecting a key to exist that I don't need or use.
  let keyId = 'map_' + Math.floor(Math.random() * 49152);
  let keyId2 = 'map2_' + Math.floor(Math.random() * 49152);

  // Show a map and table section for the Kakadu access report
  // Do not use/insert an iframe here, we will do that in code --> jscommon::createAccessReportMap()
  if (location.pathname === '/access/' && (whichPark === 'knp' || whichPark === 'cinp')) {
    const getData = () => {
      if (whichPark === 'knp') {
        return getKARdata();
      } else if (whichPark === 'cinp') {
        return getCIARdata();
      } else {
        return null;
      }
    };

    const locationData = getData();
    const locationGraphQLNodeTitle = () => {
      switch (whichPark) {
        case 'knp':
          return 'allNodeKarLocation';
        case 'cinp':
          return 'allNodeCinpLocation';
        default:
          return null;
      }
    };
    const locationTitle = locationGraphQLNodeTitle();

    if (!locationTitle) {
      console.log('Map data not found! Omitting map');
      return <React.Fragment key={randomKey()}></React.Fragment>;
    }

    // Just get the nodes object from the data returned by the query
    locationString = JSON.stringify(locationData[locationTitle].nodes);
    // Remove unwanted HTML tags, newlines and carriage returns from the rendered string
    locationString = locationString
      .replace(/(\r\n|\r|\n)/gi, '')
      .replace(/<p[^>]*>/gi, '')
      .replace(/<\/p>/gi, '')
      .replace('\r', '')
      .replace(/<\/a>/gi, '')
      .replace(/<\/a>/gi, '')
      .replace('\n', '');

    let mapPoints = [],
      showMapKey = false;

    for (let n in locationData[locationTitle].nodes) {
      let node = locationData[locationTitle].nodes[n];
      let newNode = {};
      // Go through the objects/paragraphs and find a lat/lng for this feature
      let lat = parseFloat(node.field_kar_latitude) || null,
        lng = parseFloat(node.field_kar_longitude) || null;

      // Assign it to a standard variable name
      node.lat = isNaN(lat) ? null : lat;
      node.lng = isNaN(lng) ? null : lng;

      if (node.lat !== null && node.lng !== null) {
        showMapKey = true;
      }

      node.comments =
        node.relationships?.field_kar_comments && node.relationships?.field_kar_comments.name
          ? node.relationships?.field_kar_comments.name
          : '';
      node.additionalComments =
        node.field_kar_additional_comments && node.field_kar_additional_comments?.value
          ? node.field_kar_additional_comments?.value
          : '';
      node.additionalComments = node.additionalComments.replace(/(<([^>]+)>)/gi, '');

      node.statusDisplay =
        node.field_kar_status.charAt(0).toUpperCase() +
        node.field_kar_status.substr(1).toLowerCase();

      // Create a simplified object with only the properties that we need for the map point/popup
      newNode.id = n;
      newNode.title = node.title;
      newNode.lat = node.lat;
      newNode.lng = node.lng;
      newNode.region = '';
      try {
        newNode.region = node.relationships?.field_kakadu_region?.title;
      } catch (e) {}
      newNode.status = node.field_kar_status; // === 'open';
      newNode.statusDisplay =
        newNode.status === 'open_caution'
          ? 'Open, but beware of risks'
          : newNode.status === 'open'
          ? 'Open'
          : 'Closed';
      let statusColour =
        newNode.status === 'open'
          ? ''
          : newNode.status === 'open_caution'
          ? 'border:1px solid #B45F06; background:#F1C232;'
          : 'border:1px solid #e61f00; background:#fcd8d8;';
      newNode.colour =
        newNode.status === 'open'
          ? '#78be20'
          : newNode.status === 'open_caution'
          ? '#FF6600'
          : '#CB1517';
      newNode.bgColour =
        newNode.status === 'open' ? '' : newNode.status === 'open_caution' ? '#f1c232' : '#fcd8d8';
      newNode.comments = node.comments;
      newNode.additionalComments = node.additionalComments;
      newNode.text =
        node.comments +
        `<div style="font-weight:bold; margin:5px;${statusColour}">` +
        newNode.statusDisplay +
        `</div>`;
      mapPoints.push(newNode);
    }
    // Append a script tag with the locations as an object, then call the
    // function createAccessReportMap() to make the map happen.
    sourceDoc += `<script>
      try{locationData = ${JSON.stringify(mapPoints)};
      createAccessReportMap(locationData,${latNum},${lonNum},${zoom}); }catch(e){console.log("ERROR",e);}
    </script>
    </body>`;

    const buildTableRow = (node) => {
      return (
        <tr key={node.title}>
          <td aria-label="Title">{node.title}</td>
          <td aria-label="Status" style={{ fontWeight: 'bold', color: node.colour }}>
            {node.statusDisplay}
          </td>
          <td aria-label="Comments" dangerouslySetInnerHTML={{ __html: node.additionalComments }} />
        </tr>
      );
    };

    const buildTableSubheaderRow = (node) => {
      const hasRegion = (node) => {
          return node.region ? true : false;
        },
        locationGroupTitle = () => (hasRegion(node) ? node.region : 'Main Roads');

      return (
        <React.Fragment key={node.title}>
          <tr>
            <th
              style={{
                backgroundColor: '#f9f9f9',
                color: '#555',
                fontVariation: 'uppercase',
                fontWeight: 'bold',
              }}
              colSpan="3"
              className="pl-large"
            >
              {locationGroupTitle()}
            </th>
          </tr>
          <tr>
            <td>{node.title}</td>
            <td style={{ fontWeight: 'bold', color: node.colour }}>{node.statusDisplay}</td>
            <td dangerouslySetInnerHTML={{ __html: node.additionalComments }} />
          </tr>
        </React.Fragment>
      );
    };

    // Sort the map points by region so those without one are first
    if (parkHasRegions) {
      mapPoints.sort((a, b) => {
        return a.region === b.region ? 0 : a.region > b.region ? 1 : -1;
      });
    }

    const tableItems = mapPoints.map(function (node, i, array) {
      // Non-KAR locations
      if (!parkHasRegions) {
        return buildTableRow(node);
      } else {
        // KAR locations
        if (i === 0 || (i > 0 && node.region !== array[i - 1].region)) {
          return buildTableSubheaderRow(node);
        } else {
          return buildTableRow(node);
        }
      }
    });

    return (
      <div
        key={keyId2}
        style={{
          background: 'white',
          color: 'black',
          display: 'block',
          minHeight: '300px',
          padding: 0,
          border: 0,
          minWidth: '300px',
        }}
      >
        <div
          id="access-report-map-wrapper"
          className="embed-map-box"
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            overflow: 'hidden',
            minWidth: '300px',
            minHeight: '600px',
            maxHeight: '100vh',
            background: 'white',
            color: 'black',
            padding: 0,
            border: 0,
            margin: 0,
          }}
        >
          <Iframe content={sourceDoc} key={keyId} title={frameTitle} id={mapId} />
        </div>
        <div className="grid-wrapper">
          <div className="grid-row clearfix">
            {showMapKey && (
              <div
                className="pt-medium"
                role="dialog"
                aria-labelledby="mapLegendTitle"
                aria-describedby="mapLegendPoints"
              >
                <span id="mapLegendTitle" className="map-embed__title">
                  {' '}
                  Map legend:
                </span>
                <ul id="mapLegendPoints">
                  <li>
                    <img
                      width="30"
                      height="30"
                      src="/icons/map-point-green.svg"
                      alt="Green map pin that indicates a park location is open for use"
                    />{' '}
                    Open
                  </li>
                  <li>
                    <img
                      width="30"
                      height="30"
                      src="/icons/map-point-orange.svg"
                      alt="Orange map pin that indicates a park location is open conditionally for use"
                    />{' '}
                    Open, but beware of risks
                  </li>
                  <li>
                    <img
                      width="30"
                      height="30"
                      src="/icons/map-point-red.svg"
                      alt="Red map pin that indicates a park location is closed for use or not operational"
                    />{' '}
                    Closed
                  </li>
                </ul>
              </div>
            )}
            <div className=" grid-col-12 copy mt-large">
              <table id="access-report-table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th className="status-table__site-column" scope="col">
                      Visitor site
                    </th>
                    <th className="status-table__status-column" scope="col">
                      Status
                    </th>
                    <th className="status-table__comment-column" scope="col">
                      Comments
                    </th>
                  </tr>
                </thead>
                <tbody>{tableItems}</tbody>
              </table>
            </div>
          </div>
        </div>
        <script src="/js/jscommon.js"></script>
      </div>
    );
  } else {
    // Otherwise, initialise the normal embedded map and shove it in an iframe
    // This object is inside the global 'window' object because I had real hassles getting the
    // Parks information between browsers and when not logged in.
    sourceDoc += `<script src="/js/map-init-embed-map-component.js"></script></body>`;
    return <>{<Iframe key={keyId} content={sourceDoc} title={frameTitle} id={wrapperId} />}</>;
  }
}

export default MapEmbed;
