import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import AdminInfo from '../components/structure/admin-info';
import { PageMetadata } from '../components/structure/head';
import { getParagraph } from '../components/paragraphs/paragraphs';
import { MailChimpOptInFormBlocks } from '../components/blocks/mailchimp-opt-in-form';
import { MessageSomePagesBlock } from '../components/blocks/message-some-pages';
import PageTitleSection from '../components/structure/page-header';
import { SiteWideMessageBlock } from '../components/blocks/site-wide-message';
import { TileImagesSomePagesBlocks } from '../components/blocks/tile-images-some-pages';
import { createPageParentTrail } from '../functions/build_menus/build-menu-tree';
import {
  convertBytesToHumanReadableFileSize,
  getFileExtension
} from '../functions/common';
import Link from '../components/elements/link';
import { DevAids } from '../components/dev-aids';

export default function PublicationTemplate({ data, pageContext }) {
  const park = process.env.GATSBY_PARK,
    thisPage = data.nodePublication,
    isLive = thisPage.status,
    state = thisPage.moderation_state,
    nodeID = thisPage.drupal_internal__nid,
    title = thisPage.title,
    path = pageContext?.alias || null,
    breadcrumbData = createPageParentTrail(pageContext.siteMenuData, nodeID, path) || null,
    documentSet = thisPage.relationships.field_document_set,
    coverImageAlt = thisPage.relationships.field_image?.field_media_image?.alt || null,
    coverImage =
      getImage(
        thisPage.relationships.field_image?.localImageSmall?.childImageSharp?.gatsbyImageData
      ) ||
      thisPage.relationships.field_image?.localImageSmall?.publicURL ||
      null,
    subtitle = thisPage.field_subtitle || null,
    paragraphs = thisPage.relationships.paragraphs.map((item) => getParagraph(item, pageContext));

  const documents = documentSet.map((doc, Index: Number) => {
    const url = doc.customLocalFieldMediaFile?.publicURL || null,
      documentName =
        doc.relationships?.field_media_file?.relationships?.media__document[0].name ||
        doc.relationships?.field_media_file?.filename ||
        doc.relationships?.field_media_file?.uri?.url;
    null;
    return documentName && url ? (
      <li key={doc.relationships?.field_media_file?.id}>
        <Link to={url} target="_blank" rel="noopener noreferrer">
          {documentName} {getFileExtension(url)} -{' '}
          {convertBytesToHumanReadableFileSize(doc.relationships.field_media_file?.filesize)}
        </Link>
      </li>
    ) : (
      <React.Fragment key={doc.relationships?.field_media_file?.id}></React.Fragment>
    );
  });

  return (
    <>
      <PageMetadata
        title={title}
        description={thisPage.body?.summary || title}
        thumbnailImage={thisPage.relationships.field_image?.localImageSmall?.publicURL}
        path={path}
        dateModified={thisPage.changed}
      />

      {/* If not the homepage, add the title header and social links */}
      {path !== '' ? (
        <PageTitleSection
          title={title}
          socialMediaBlock={data.blockContentSocialMedia}
          breadcrumbs={breadcrumbData}
          summaryText={thisPage?.body?.summary || null}
        />
      ) : (
        ''
      )}

      {/* Main content */}
      <main id="content">
        <div className="grid-wrapper">
          <div className="grid-row clearfix">
            <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
              <div className="copy">
                {/* Show an Edit link for Gatsby Preview environments */}
                <AdminInfo status={isLive} moderationState={state} path={path} />
                <DevAids node={thisPage} pageLinks={true} />
                <SiteWideMessageBlock
                  callingPark={park}
                  nodes={data.allBlockContentSiteWideMessage}
                />

                <MessageSomePagesBlock
                  nodeID={nodeID}
                  nodes={data.allBlockContentMessageSomePages}
                  callingPark={park}
                />

                {subtitle && <p>{subtitle}</p>}
                {coverImage ? (
                  typeof coverImage === 'object' ? (
                    <figure className="figure-float-right ">
                      <GatsbyImage image={coverImage} alt={coverImageAlt} loading="eager" />
                    </figure>
                  ) : (
                    <figure className="figure-float-right">
                      <img src={coverImage} alt={coverImageAlt} loading="eager" />
                    </figure>
                  )
                ) : (
                  <React.Fragment key={data.id}></React.Fragment>
                )}
                {documents.length != 0 && <h2 id="download">Download</h2>}
                <ul>{documents}</ul>
                {thisPage.body?.processed ? (
                  <div
                    className="body-content"
                    dangerouslySetInnerHTML={{
                      __html: thisPage.body?.processed,
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* Paragraphs */}
      {paragraphs}
      {/* Mailchimp Subscribe forms */}
      <MailChimpOptInFormBlocks
        nodes={data.allBlockContentMailchimpOptInForm}
        pagePath={pageContext.alias}
      />
      {/* Tile images on some pages Block */}
      <TileImagesSomePagesBlocks
        nodes={data.allBlockContentTileImagesSomePages}
        pagePath={pageContext.alias}
      />
    </>
  );
}

// This queries pages by their Drupal ID (UUID), not their Node ID
export const query = graphql`
  query ($park: String!, $drupalId: String!) {
    nodePublication(drupal_id: { eq: $drupalId }) {
      id
      drupal_id
      drupal_internal__nid
      title
      created
      changed
      moderation_state
      status
      body {
        processed
        summary
      }
      field_subtitle
      field_site {
        drupal_internal__target_id
      }
      path {
        alias
      }
      relationships {
        field_document_set {
          id
          relationships {
            field_media_file {
              ...FileInfo
            }
          }
          customLocalFieldMediaFile {
            publicURL
          }
        }
        field_image {
          ...MediaDataContentImage
        }
        paragraphs: field_paragraphs {
          type: __typename
          # ...ParagraphAccordionContainer
          ...ParagraphButtonsContainer
          # ...ParagraphDownloadsList
          # ...ParagraphEmbedIframe
          # ...ParagraphEmbedMap
          # ...ParagraphEmbedScript
          # ...ParagraphEmbedVideo
          # ...ParagraphFeedList
          ...ParagraphFigure
          # ...ParagraphGalleryGrid
          # ...ParagraphHeroImageSection
          # ...ParagraphPeople
          # ...ParagraphQuoteWithImage
          # ...ParagraphSectionHeadingLinks
          ...ParagraphTable
          ...ParagraphText
          #   ...ParagraphTileImages
        }
      }
    }
    allBlockContentSiteWideMessage(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...SiteWideMessageBlockQuery
    }
    allBlockContentMessageSomePages(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...MessageSomePagesBlockQuery
    }
    allBlockContentTileImagesSomePages(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...TileImagesSomePagesBlockQuery
    }
    blockContentSocialMedia {
      ...SocialMediaLinksBlockQuery
    }
    allBlockContentMailchimpOptInForm(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...MailChimpOptInFormBlockQuery
    }
  }
`;
