import React from 'react';
import { graphql } from 'gatsby';
import Link from '../elements/link';
import {
  getParkNames,
  stripUrlParkPrefix,
  isDummyOrTestContent,
  testEnv,
} from '../../functions/common';

export const SocialMediaLinksBlock = ({ node, title, pageURI, summaryText }) => {
  // Generate a fully-qualified URL, as if we share an absolute /path/like/this, the
  // remote service won't know which site we're talking about.
  const fullURI = `https://${getParkNames().domain}${stripUrlParkPrefix(pageURI)}` || null;

  const links = node?.relationships?.field_social_media_link || null;

  if (links && fullURI && isDummyOrTestContent(node.info) === false) {
    const linksList = links.map((link, index: Number) => {
      const imgSrc =
          link?.relationships?.field_social_media_svg_icon?.customLocalFieldMediaImage1?.publicURL || null,
        serviceName = link.field_social_media_link_title,
        serviceLinkPrefix = link?.field_social_media_link_url?.uri || '';

      // Define the text we will append to each social media service,
      // as it varies between services.

      let linkStr = '';

      switch (serviceName.toLowerCase()) {
        case 'facebook':
          linkStr = fullURI;
          break;
        case 'twitter':
        case 'x':
          // Create the hashtag from the park shortname, and remove any dashes
          linkStr = `${encodeURI(
            title
          )}&url=${fullURI}&hashtag=see${getParkNames().shortName.replace('-', '')}`;
          break;
        case 'reddit':
          linkStr = `${fullURI}&title=${encodeURI(title)}`;
          break;
        case 'pinterest':
          linkStr = `${fullURI}&media=${imgSrc}&description=${encodeURI(title)}`;
          break;
        case 'email':
          summaryText ? (summaryText = `${summaryText}\n\n`) : (summaryText = '');
          linkStr = `subject=${encodeURI(title)}${encodeURI(' - ')}${encodeURI(getParkNames().fullName)}&body=${encodeURI(summaryText)}${fullURI}`;
          break;
        default:
          testEnv().devMode
            ? console.warn(
                `[ISSUE - Links]: Unrecognised social media service name in Social Media Block ID ${node.drupal_id}, skipping...`
              )
            : null;
          break;
      }

      return (
        <li className="page-header__social-icon" key={link?.field_social_media_link_url.uri}>
          <Link
            className="share-icon hide-text"
            to={`${serviceLinkPrefix}${linkStr}`}
            target="_blank"
            title={`Share this page with ${link.field_social_media_link_title}`}
            rel="noopener noreferrer"
          >
            {imgSrc ? (
              <img
                src={imgSrc}
                alt={`Share on ${link.field_social_media_link_title}`}
                loading="eager"
              />
            ) : (
              <React.Fragment key={link.id}></React.Fragment>
            )}
          </Link>
        </li>
      );
    });
    return linksList ? linksList : <React.Fragment key={links.id}></React.Fragment>;
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export const fragment = graphql`
  fragment SocialMediaLinksBlockQuery on block_content__social_media {
    id
    drupal_id
    info
    relationships {
      field_social_media_link {
        id
        field_shared_text
        field_social_media_link_title
        field_social_media_link_url {
          title
          uri
        }
        relationships {
          field_social_media_svg_icon {
            ...MediaDataSvgIcon
          }
        }
      }
    }
  }
`;
